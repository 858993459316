import "./App.css";
import React, { useState, useEffect } from "react";
import axios from "axios";

const TEST_BACKEND_URL = "https://picksell-oauth.monerchy.workers.dev";
const RETURN_URL = "https://https://pay-demo.pages.dev";
// const RETURN_URL = "http://localhost:3000";
const HEADERS = {
  "Content-Type": "application/json",
};

const OAUTH_MERCHANT_ID = "33";
const OAUTH_RETURN_URL = "https://picksell-oauth.monerchy.workers.dev";

function App() {
  const [amount, setAmount] = useState(10);
  const [authToken, setAuthToken] = useState(undefined);
  const [profile, setProfile] = useState();

  const loadUserProfile = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: "https://oauth.psd2.club/customer/profile",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.data) {
        setProfile(JSON.stringify(response.data));
        // eslint-disable-next-line no-restricted-globals
        history.pushState({}, null, "/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get(
      "access_token"
    );
    if (token?.length > 0) {
      setAuthToken(token);
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      loadUserProfile();
    }
  }, [authToken]);

  const handleOAuth = async () => {
    // eslint-disable-next-line no-undef
    PicksellPaySDK.authWithPicksellPay(OAUTH_MERCHANT_ID, OAUTH_RETURN_URL, [
      "read:name",
      "read:phone",
      "read:address",
      "read:recipient",
    ]);
  };

  const handlePay = async (environment, isBuy = true) => {
    try {
      const response = await fetch(
        `${TEST_BACKEND_URL}/checkout?env=${environment}`,
        {
          method: "POST",
          headers: HEADERS,
          body: JSON.stringify({
            description: "Some item",
            currency: "EUR",
            amount: String(amount),
            returnUrl: RETURN_URL,
          }),
        }
      );

      const jsonResponse = await response.json();

      // const paymentURL = `http://localhost:3001/orders/${jsonResponse.payload.id}`;
      const paymentURL = jsonResponse.payload.paymentUrl;

      if (isBuy) {
        // eslint-disable-next-line no-undef
        PicksellPaySDK.buyWithPicksellPay(paymentURL);
      } else {
        // eslint-disable-next-line no-undef
        PicksellPaySDK.checkPicksellPayTransaction(`${paymentURL}/check`, {
          experimental: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="App">
      <h1>Payments</h1>
      <span>Amount to pay&nbsp;</span>
      <input
        placeholder="Amount to pay"
        onChange={(e) => setAmount(e.target.value)}
        value={amount}
      />
      <h2>Sandbox payment (sandbox psd2 merchant ID 33)</h2>

      <button
        type="submit"
        className="picksell_pay_buy_button"
        onClick={() => handlePay("psd2_sandbox")}
      >
        Submit
      </button>

      <h2>Create payment and check status (sandbox psd2 merchant ID 33)</h2>

      <button
        type="submit"
        className="picksell_pay_buy_button"
        onClick={() => handlePay("psd2_sandbox", false)}
      >
        Submit
      </button>

      <h2>Create payment and check status (production psd2 merchant ID 311)</h2>

      <button
        type="submit"
        className="picksell_pay_buy_button"
        onClick={() => handlePay("psd2", false)}
      >
        Submit
      </button>
      <h2>Non-sandbox payment (production psd2 merchant ID 311) </h2>
      <button
        type="submit"
        className="picksell_pay_buy_button"
        onClick={() => handlePay("psd2")}
      >
        Submit
      </button>

      <h1>OAuth</h1>
      <button
        type="submit"
        className="picksell_pay_oauth_button"
        onClick={handleOAuth}
      >
        Login
      </button>

      <div>
        {authToken
          ? `Auth token: ${authToken.slice(0, 15)}...`
          : "Not logged in"}
      </div>
      {profile && <div>{profile}</div>}
    </div>
  );
}

export default App;
